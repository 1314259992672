<style scoped>
div {
  background: rgba(0, 0, 0, 0) !important;
}
</style>

<template>
  <AppButtonCollapse
    icon="mdi-book-open-page-variant-outline"
    messageHidden="Literatur anzeigen"
    messageNotHidden="Literatur verstecken"
    elevation="0"
  >
    <div>
      <v-simple-table>
        <template v-slot:default>
          <tbody>
            <tr>
              <td>
                Förderzentrum Mathematik (o.J.). Zahlenrechnen.
                https://foerderzentrum.mathematik.tu-dortmund.de/drupal/mathematische-basiskompetenzen/zahlenrechnen#1
              </td>
            </tr>
            <tr>
              <td>
                Götze, D., Selter, Ch. & Zannetin, E. (2019). Das KIRA-Buch.
                Kinder rechnen anders. Verstehen und Fördern im
                Mathematikunterricht. Hannover: Kallmeyer.
              </td>
            </tr>
            <tr>
              <td>
                Krauthausen, G. & Scherer, P. (2007). Einführung in die
                Mathematikdidaktik. 3. Aufl. Heidelberg: Springer Spektrum.
              </td>
            </tr>
            <tr>
              <td>
                Mathe sicher können (o.J.). Natürliche Zahlen – Zahlenrechnen.
                https://mathe-sicher-koennen.dzlm.de/node/512
              </td>
            </tr>
            <tr>
              <td>
                Moser Opitz, E. & Scherer, P. (2010). Fördern im
                Mathematikunterricht der Primarstufe. Heidelberg: Springer
                Spektrum.
              </td>
            </tr>
            <tr>
              <td>
                Padberg, F. & Benz, C. (2021). Didaktik der Arithmetik:
                Fundiert, vielseitig, praxisnah. 5., überarbeitete Aufl.
                Heidelberg: Springer Spektrum.
              </td>
            </tr>
            <tr>
              <td>
                Schmassmann, M. & Moser Opitz, E. (2011). Heilpädagogischer
                Kommentar zum Schweizer Zahlenbuch 4. Hinweise zur Arbeit mit
                Kindern mit mathematischen Lernschwierigkeiten (vollständig
                überarbeitete Neuauflage). Zug: Klett & Balmer.
              </td>
            </tr>
            <tr>
              <td>
                Selter, Ch. & Zannetin, E. (2019): Mathematik unterrichten in
                der Grundschule. Inhalte – Leitideen – Beispiele. 2. Aufl.
                Seelze: Klett Kallmeyer.
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </AppButtonCollapse>
</template>

<script>
import AppButtonCollapse from "@/common/AppButtonCollapse";

export default {
  name: "AppLiteraturHR",
  components: {
    AppButtonCollapse,
  },
};
</script>

<template>
  <div>
    <h2 class="faledia-caption">Strategien des halbschriftlichen Rechnens</h2>
    <v-card outlined>
      <v-card-title>Was sollen die Schüler:innen lernen?</v-card-title>
      <v-card-text>
        Die Kinder können...
        <ul>
          <li>
            verschiedene Strategien des halbschriftlichen Rechnens
            nachvollziehen und anwenden.
          </li>
          <li>
            gegebene Rechenaufgaben mit halbschriftlichen Strategien lösen.
          </li>
        </ul>
      </v-card-text>
    </v-card>

    <br />
    <p>
      Es gibt jeweils ähnliche strategische Vorgehensweisen für die
      verschiedenen Rechenoperationen der Addition, Subtraktion, Multiplikation
      und Division. Es bieten sich jedoch nicht alle Strategien in gleicher Form
      bei jeder Operation an (Schmassmann, Moser Opitz, 2011).
    </p>
    <p>
      Im Folgenden werden Sie aufgefordert, selbst einmal zu gruppieren, welche
      Vorgehensweisen beim Lösen von Aufgaben einander entsprechen und zur
      gleichen Strategie gehören. Im Anschluss an Ihre Bearbeitung können Sie
      mit einer Übersicht über die Strategien der halbschriftlichen
      Rechenverfahren am Beispiel der Addition die Strategien noch einmal
      nachvollziehen.
    </p>
    <div style="background-color: white">
      <LernbausteinGruppen
        :id="LBSTGruppen.id"
        :checkAfterHowManyItems="LBSTGruppen.checkAfterHowManyItems"
        :checkAfterExactItemCount="LBSTGruppen.checkAfterExactItemCount"
        :showGroupNames="LBSTGruppen.showGroupNames"
        :workOrder="LBSTGruppen.workOrder"
        :possibleCategories="LBSTGruppen.possibleCategories"
        :elements="LBSTGruppen.elements"
        :responses="LBSTGruppen.responses"
      >
        <template v-slot:alternative-content>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr style="text-align: center">
                  <td colspan="3">
                    Übliche Verfahren beim halbschriftlichen Addieren
                  </td>
                </tr>
                <tr>
                  <td>Schrittweises Rechnen</td>
                  <td>
                    Einer der beiden Summanden wird zerlegt und mit den
                    Zwischenschritten weitergerechnet.
                  </td>
                  <td class="no-wrap">
                    <u>67 + 35 = 102 </u><br />
                    67 + 30 = 97 <br />
                    97 + 3 = 100 <br />
                    100 + 2 = 102
                  </td>
                </tr>
                <tr>
                  <td>
                    Stellenweises Rechnen (auch genannt „Stellenwerte extra“)
                  </td>
                  <td>
                    Beide Summanden werden in ihre Stellwerte zerlegt und die
                    Zwischenergebnisse zusammengerechnet.
                  </td>
                  <td class="no-wrap">
                    <u>46 + 38 = 84 </u><br />
                    40 + 30 = 70 <br />
                    6 + 8 = 14
                  </td>
                </tr>
                <tr>
                  <td>Vereinfachen</td>
                  <td>
                    Vereinfachungen werden durch das
                    <strong>gegensinnige</strong> Verändern von erstem und
                    zweitem Summanden vorgenommen. Demnach wird unter Ausnutzung
                    von Konstanzgesetzen eine einfachere Aufgabe gerechnet, die
                    aber dasselbe Ergebnis wie die eigentliche Aufgabe hat.
                  </td>
                  <td class="no-wrap">
                    <u>78 + 52 = 130</u><br />
                    80 + 50 = 130
                  </td>
                </tr>
                <tr>
                  <td>Hilfsaufgabe</td>
                  <td>
                    Eine ähnliche Aufgabe, bei der es leichter ist das Ergebnis
                    zu ermitteln, wird durch verändern einer Zahl (oder beider
                    Zahlen) ersatzweise gerechnet. Im Anschluss erfolgt eine
                    nachträgliche Korrektur, um auf das Ergebnis der
                    Ausgangsaufgabe zu kommen.
                  </td>
                  <td class="no-wrap">
                    <u>41 + 17 = 58 </u><br />
                    40 + 17 = 57 <br />
                    57 + 1 = 58
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          (siehe auch Götze, Selter, Zannetin, 2019)
        </template>
      </LernbausteinGruppen>
    </div>

    <p>
      In Ihrer Bearbeitung haben Sie nun sehr eindeutige Beispiele gesehen, die
      klar einer Strategie zugeordnet werden konnten. Ein ganz konkreter
      Rechenweg ist dabei allerdings nicht fest vorgegeben (Krauthausen,
      Scherer, 2007). Daher nutzen Kinder darüber hinaus durchaus auch
      sogenannte Mischformen, bei denen beispielsweise zum Teil schrittweise und
      zum Teil stellenweise gerechnet wird.
    </p>
    <p>
      Die halbschriftlichen Strategien zur Addition haben Sie nun bereits
      kennengelernt. Nachfolgend haben Sie die Möglichkeit sich die
      halbschriftlichen Verfahren zu den anderen drei Grundrechenarten
      anzusehen. Grundsätzlich ist es immer auch von der konkreten Aufgabe und
      individuellen Präferenzen abhängig, wie genau gerechnet wird (Padberg,
      Benz, 2021).
    </p>
    <p>
      <strong
        >Typische Vorgehensweisen bei der halbschriftlichen Subtraktion</strong
      >
    </p>
    <AppButtonCollapse
      messageHidden="Tabelle anzeigen"
      messageNotHidden="Tabelle verstecken"
    >
      <v-simple-table>
        <template v-slot:default>
          <tbody>
            <tr style="text-align: center">
              <td colspan="3">
                Übliche Verfahren beim halbschriftlichen Subtrahieren
              </td>
            </tr>
            <tr>
              <td>Schrittweises Rechnen</td>
              <td>
                Der Subtrahend wird (z.B. in seine Stellenwerte) zerlegt und
                schrittweise vom Minuenden subtrahiert.
              </td>
              <td class="no-wrap">
                <u>342 – 161 = 181 </u><br />
                342 – 100 = 242 <br />
                242 – 40 = 202 <br />
                202 – 21 = 181
              </td>
            </tr>
            <tr>
              <td>Stellenweises Rechnen (auch genannt „Stellenwerte extra“)</td>
              <td>
                Die Subtraktion erfolgt hier stellenweise. Es werden Hunderter,
                Zehner und Einer getrennt voneinander subtrahiert. Dabei kann es
                durchaus zu negativen Zwischenergebnissen kommen. Dies und der
                Umgang damit muss mit Kindern transparent besprochen werden.
              </td>
              <td class="no-wrap">
                <u>324 – 153 = 171 </u><br />
                300 – 100 = 200 <br />
                20 – 50 = –30 <br />
                4 – 3 = 1
              </td>
            </tr>
            <tr>
              <td>Vereinfachen</td>
              <td>
                Vereinfachungen werden durch das
                <strong>gleichsinnige</strong> Verändern von Minuend und
                Subtrahend vorgenommen. Demnach wird unter Ausnutzung von
                Konstanzgesetzen eine einfachere Aufgabe gerechnet, die aber
                dasselbe Ergebnis wie die eigentliche Aufgabe hat.
              </td>
              <td class="no-wrap">
                <u>162 – 57 = 105 </u><br />
                165 – 60 = 105
              </td>
            </tr>
            <tr>
              <td>Hilfsaufgabe</td>
              <td>
                Eine ähnliche Aufgabe, bei der es leichter ist das Ergebnis zu
                ermitteln, wird durch verändern einer Zahl (oder beider Zahlen)
                ersatzweise gerechnet. Im Anschluss erfolgt eine nachträgliche
                Korrektur, um auf das Ergebnis der Ausgangsaufgabe zu kommen.
              </td>
              <td class="no-wrap">
                <u>438 – 127 = 311 </u><br />
                438 – 130 = 308 <br />
                308 + 3 = 311
              </td>
            </tr>
            <tr>
              <td>Ergänzen</td>
              <td>
                Es wird (schrittweise) vom Subtrahenden zum Minuenden ergänzt.
                Dies bietet sich insbesondere bei Aufgaben mit kleiner Differenz
                an.
              </td>
              <td class="no-wrap">
                <u>328 – 305 = 23 </u><br />
                305 + 3 = 308 <br />
                308 + 20 = 328
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      (siehe auch Götze, Selter, Zannetin, 2019)
    </AppButtonCollapse>
    <p>
      <strong
        >Typische Vorgehensweisen bei der halbschriftlichen
        Multiplikation</strong
      >
    </p>
    <AppButtonCollapse
      messageHidden="Tabelle anzeigen"
      messageNotHidden="Tabelle verstecken"
    >
      <v-simple-table>
        <template v-slot:default>
          <tbody>
            <tr style="text-align: center">
              <td colspan="3">
                Übliche Verfahren beim halbschriftlichen Multiplizieren
              </td>
            </tr>
            <tr>
              <td>Schrittweises Rechnen</td>
              <td>
                Bei der Vorgehensweise „Schrittweise" wird nur ein Faktor in
                seine Stellenwerte zerlegt. Die Teilergebnisse werden
                anschließend addiert, um das Endergebnis zu erhalten.
              </td>
              <td class="no-wrap">
                <u>152 · 11 = 1672 </u><br />
                100 · 11 = 1100 <br />
                50 · 11 = 550 <br />
                2 · 11 = 22
              </td>
            </tr>
            <tr>
              <td>Stellenweises Rechnen</td>
              <td>
                Beide Faktoren werden in ihre Stellenwerte zerlegt und können
                entweder als Teilaufgaben unter dem Strich oder im Malkreuz
                notiert werden. Die Teilergebnisse werden dann entweder
                nebeneinander oder untereinander addiert, um das Endergebnis zu
                erhalten.
              </td>
              <td class="no-wrap">
                <div>
                  <u>24 · 53 = 1272 </u><br />
                  20 · 50 = 1000 <br />
                  20 · 3 = 60 <br />
                  4 · 50 = 200 <br />
                  4 · 3 = 12
                </div>
                <div class="image">
                  <AppExpandableImage
                    contain
                    :src="require('@/assets/hr/StrategienHRTabelle.png')"
                    parentId="swve"
                    elementId="swve_darstellungswechsel.png"
                  >
                  </AppExpandableImage>
                </div>
              </td>
            </tr>
            <tr>
              <td>Vereinfachen</td>
              <td>
                Vereinfachungen werden durch das
                <strong>gegensinnige</strong> Verändern der beiden Faktoren
                vorgenommen. Demnach wird unter Ausnutzung von Konstanzgesetzen
                eine einfachere Aufgabe gerechnet, die aber dasselbe Ergebnis
                wie die eigentliche Aufgabe hat.
              </td>
              <td class="no-wrap">
                <u>35 · 40 = 1400 </u><br />
                70 · 20 = 1400
              </td>
            </tr>
            <tr>
              <td>Hilfsaufgabe</td>
              <td>
                Eine ähnliche Aufgabe, bei der es leichter ist das Ergebnis zu
                ermitteln, wird durch verändern einer Zahl (oder beider Zahlen)
                ersatzweise gerechnet. Im Anschluss erfolgt eine nachträgliche
                Korrektur, um auf das Ergebnis der Ausgangsaufgabe zu kommen.
              </td>
              <td class="no-wrap">
                <u>58 · 30 = 1740 </u><br />
                60 · 30 = 1800 <br />
                2 · 30 = 60
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      (siehe auch Götze, Selter, Zannetin, 2019)
    </AppButtonCollapse>
    <p>
      <strong
        >Typische Vorgehensweisen bei der halbschriftlichen Division</strong
      >
    </p>
    <AppButtonCollapse
      messageHidden="Tabelle anzeigen"
      messageNotHidden="Tabelle verstecken"
    >
      <v-simple-table>
        <template v-slot:default>
          <tbody>
            <tr style="text-align: center">
              <td colspan="3">
                Übliche Verfahren beim halbschriftlichen Dividieren
              </td>
            </tr>
            <tr>
              <td>Schrittweises Rechnen</td>
              <td>
                Üblicherweise wird der Dividend additiv zerlegt und die Division
                anschließend in Schritten durchgeführt. Die Teilergebnisse
                werden abschließend addiert, um das Endergebnis zu erhalten.
              </td>
              <td class="no-wrap">
                <u>102 : 6 = 17 </u><br />
                60 : 6 = 10 <br />
                42 : 6 = 7
              </td>
            </tr>
            <tr>
              <td>Stellenweises Rechnen</td>
              <td>
                Das stellenweise Rechnen funktioniert bei der Division nur in
                Ausnahmefällen. Daher ist dies keine tragfähige Strategie für
                die Division.
              </td>
              <td>
                Ein Beispiel, bei dem die stellenweise Division funktioniert:
                <br />
                <div lass="no-wrap">
                  <u>777 : 7 = 111 </u><br />
                  700 : 7 = 100 <br />
                  70 : 7 = 10 <br />
                  7 : 7 = 1
                </div>
              </td>
            </tr>
            <tr>
              <td>Vereinfachen</td>
              <td>
                Vereinfachungen werden durch das
                <strong>gleichsinnige</strong> Verändern des Dividenden und des
                Divisors vorgenommen. Demnach wird unter Ausnutzung von
                Konstanzgesetzen eine einfachere Aufgabe gerechnet, die aber
                dasselbe Ergebnis wie die eigentliche Aufgabe hat.
              </td>
              <td class="no-wrap">
                <u>120 : 8 = 15 </u><br />
                30 : 2 = 15
              </td>
            </tr>
            <tr>
              <td>Hilfsaufgabe</td>
              <td>
                Eine ähnliche Aufgabe, bei der es leichter ist das Ergebnis zu
                ermitteln, wird durch verändern einer Zahl (oder beider Zahlen)
                ersatzweise gerechnet. Im Anschluss erfolgt eine nachträgliche
                Korrektur, um auf das Ergebnis der Ausgangsaufgabe zu kommen.
              </td>
              <td class="no-wrap">
                <u>152 : 8 = 19</u><br />
                160 : 8 = 20 <br />
                8 : 8 = 1
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      (siehe auch Götze, Selter, Zannetin, 2019)
    </AppButtonCollapse>

    <AppLiteraturHR />
    <AppBottomNavHR
      back="/zahlenrechnen/hintergrundwissen"
      next="/zahlenrechnen/hintergrundwissen/flexibler-einsatz-von-rechenstrategien"
    />
  </div>
</template>

<script>
import AppBottomNavHR from "@/common/AppBottomNavHR";
import AppLiteraturHR from "@/common/AppLiteraturHR";
import AppButtonCollapse from "@/common/AppButtonCollapse";
import LernbausteinGruppen from "@/components/faledia/explorativeElemente/LernbausteinGruppen";
import AppExpandableImage from "@/common/AppExpandableImage";

import LBST_Gruppen_HR from "@/components/faledia/seiten/zahlenrechnen/LBST/LBST_Gruppen_V2_HR";

export default {
  components: {
    AppBottomNavHR,
    AppLiteraturHR,
    AppButtonCollapse,
    LernbausteinGruppen,
    AppExpandableImage,
  },
  data: () => ({
    LBSTGruppen: LBST_Gruppen_HR,
  }),
};
</script>

<style scoped>
#app .no-wrap {
  white-space: nowrap;
  overflow: auto;
}
.image {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 179px;
}
</style>

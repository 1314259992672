function createObject() {
  let example = {
    id: "LBST_Gruppen_V2_HR",
    showGroupNames: false,
    checkAfterHowManyItems: 3,
    checkAfterExactItemCount: true,
    workOrder:
      "Sie sehen hier Additionsaufgaben, die Kinder mithilfe verschiedener halbschriftlicher Strategien gerechnet haben. Ziehen sie jeweils 3 Aufgaben in eine Gruppe, bei denen Ihrer Meinung nach dieselbe Strategie verwendet wurde.",
    possibleCategories: [
      {
        id: 1,
        name: "Stellenweise",
      },
      {
        id: 2,
        name: "Schrittweise",
      },
      {
        id: 3,
        name: "Hilfsaufgabe",
      },
      {
        id: 4,
        name: "Vereinfachen",
      },
    ],
    elements: [
      {
        id: 1,
        image: require("@/assets/hr/Gruppen/HR/01.png"),
        category: 1,
        flex: 3,
      },
      {
        id: 2,
        image: require("@/assets/hr/Gruppen/HR/02.png"),
        category: 4,
        flex: 3,
      },
      {
        id: 3,
        image: require("@/assets/hr/Gruppen/HR/03.png"),
        category: 2,
        flex: 3,
      },
      {
        id: 4,
        image: require("@/assets/hr/Gruppen/HR/04.png"),
        category: 3,
        flex: 3,
      },
      {
        id: 5,
        image: require("@/assets/hr/Gruppen/HR/05.png"),
        category: 1,
        flex: 3,
      },
      {
        id: 6,
        image: require("@/assets/hr/Gruppen/HR/06.png"),
        category: 2,
        flex: 3,
      },
      {
        id: 7,
        image: require("@/assets/hr/Gruppen/HR/07.png"),
        category: 3,
        flex: 3,
      },
      {
        id: 8,
        image: require("@/assets/hr/Gruppen/HR/08.png"),
        category: 1,
        flex: 3,
      },
      {
        id: 9,
        image: require("@/assets/hr/Gruppen/HR/09.png"),
        category: 4,
        flex: 3,
      },
      {
        id: 10,
        image: require("@/assets/hr/Gruppen/HR/10.png"),
        category: 3,
        flex: 3,
      },
      {
        id: 11,
        image: require("@/assets/hr/Gruppen/HR/11.png"),
        category: 4,
        flex: 3,
      },
      {
        id: 12,
        image: require("@/assets/hr/Gruppen/HR/12.png"),
        category: 2,
        flex: 3,
      },
    ],
  };

  const zweiRichtig =
    "Zwei der Aufgaben zeigen dieselbe Strategie, eine zeigt jedoch eine andere Strategie. ";

  const nichtPassend =
    "Upps, das ist noch sehr durcheinander. Betrachten Sie noch einmal die Zwischenschritte der Rechnung. Wie wird mit den beiden Summanden umgegangen? ";

  example.responses = [
    {
      id: "0003",
      category: example.possibleCategories[3],
      text:
        "Richtig, hier wird jeweils mit der Strategie Vereinfachen gerechnet. Bei dieser Strategie wird die Aufgabe durch gegensinniges Verändern vereinfacht, sodass eine leichtere Aufgabe gerechnet werden kann, die zum selben Ergebnis führt, wie die Ausgangsaufgabe.",
    },
    {
      id: "0012",
      text:
        zweiRichtig +
        "Beide Strategien wirken ähnlich jedoch wird in einem Fall eine ähnliche, einfachere Aufgabe aufgestellt und anschließende korrigiert, um das richtige Ergebnis zu erhalten und im anderen Fall beide Summanden gegensinnig verändert, sodass eine andere Aufgabe mit gleicher Summe gerechnet wird.",
    },
    {
      id: "0021",
      text:
        zweiRichtig +
        "Beide Strategien wirken ähnlich jedoch wird in einem Fall eine ähnliche, einfachere Aufgabe aufgestellt und anschließende korrigiert, um das richtige Ergebnis zu erhalten und im anderen Fall beide Summanden gegensinnig verändert, sodass eine andere Aufgabe mit gleicher Summe gerechnet wird.",
    },
    {
      id: "0030",
      category: example.possibleCategories[2],
      text:
        "Prima. In all diesen Aufgaben wird mit der Strategie Hilfsaufgabe gerechnet. Bei dieser Strategie wird eine ähnliche, einfachere Aufgabe aufgestellt und anschließende korrigiert, um das richtige Ergebnis zu erhalten.",
    },
    {
      id: "0111",
      text: nichtPassend,
    },
    {
      id: "0102",
      text:
        zweiRichtig +
        "Betrachten Sie noch einmal die Zwischenschritte der Rechnung. Folgen diese jeweils dem gleichen Schema?",
    },
    {
      id: "0120",
      text:
        zweiRichtig +
        "Betrachten Sie noch einmal die Zwischenschritte der Rechnung. Folgen diese jeweils dem gleichen Schema?",
    },
    {
      id: "0201",
      text:
        zweiRichtig +
        "Betrachten Sie noch einmal die Zwischenschritte der Rechnung. Folgen diese jeweils dem gleichen Schema?",
    },
    {
      id: "0210",
      text:
        zweiRichtig +
        "Betrachten Sie noch einmal die Zwischenschritte der Rechnung. Folgen diese jeweils dem gleichen Schema?",
    },
    {
      id: "0300",
      category: example.possibleCategories[1],
      text:
        "Sehr gut. Alle drei Aufgaben zeigen die Strategie Schrittweise. Bei dieser Strategie wird einer der Summanden zerlegt und es wird mit Teilergebnissen weiter gerechnet.",
    },
    {
      id: "1002",
      text:
        zweiRichtig +
        "Betrachten Sie noch einmal die Zwischenschritte der Rechnung. Folgen diese jeweils dem gleichen Schema?",
    },
    {
      id: "1011",
      text: nichtPassend,
    },
    {
      id: "1020",
      text:
        zweiRichtig +
        "Betrachten Sie noch einmal die Zwischenschritte der Rechnung. Folgen diese jeweils dem gleichen Schema?",
    },
    {
      id: "1101",
      text: nichtPassend,
    },
    {
      id: "1110",
      text: nichtPassend,
    },
    {
      id: "1200",
      text:
        zweiRichtig +
        "Betrachten Sie noch einmal die Zwischenschritte der Rechnung. Folgen diese jeweils dem gleichen Schema?",
    },
    {
      id: "2001",
      text:
        zweiRichtig +
        "Betrachten Sie noch einmal die Zwischenschritte der Rechnung. Folgen diese jeweils dem gleichen Schema?",
    },
    {
      id: "2010",
      text:
        zweiRichtig +
        "Betrachten Sie noch einmal die Zwischenschritte der Rechnung. Folgen diese jeweils dem gleichen Schema?",
    },
    {
      id: "2100",
      text:
        zweiRichtig +
        "Betrachten Sie noch einmal die Zwischenschritte der Rechnung. Folgen diese jeweils dem gleichen Schema?",
    },
    {
      id: "3000",
      category: example.possibleCategories[0],
      text:
        "Super. In allen drei Aufgaben wird mit der Strategie Stellenweise gerechnet. Bei dieser Strategie werden beide Summanden in ihre Stellwerte zerlegt, erst einzeln ausgerechnet und anschließend die Teilsummen miteinander addiert.",
    },
  ];

  return example;
}

export default createObject();
